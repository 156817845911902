import axios from "axios";
import {baseUrl, getAxiosRequestOptions, handleAxiosError} from "./base";
import {QueryClient, useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {EprEntriesList} from "../types/Entry";

export const useFetchEntriesInfiniteQuery = (limit: number) => {
    return useInfiniteQuery({
        queryKey: ["epr", "entries-infinite"],
        queryFn: ({pageParam}) => fetchEntries(pageParam, limit),
        initialPageParam: "",
        getNextPageParam: (_lastPage, allPages) => allPages[allPages.length - 1].nextEntryWhen,
        staleTime: 10 * 60 * 1000,
        gcTime: 10 * 60 * 1000,
    });
}

export const fetchEntries = async (fromDateTime: string, limit: number): Promise<EprEntriesList> => {
    return axios.post(
        `${baseUrl}/epr/list`,
        {"fromDateTime": fromDateTime, "maxResults": limit},
        getAxiosRequestOptions(),
    ).then((rsp) => {
        let res: EprEntriesList = rsp.data;
        if (!res.nextEntryWhen) res.nextEntryWhen = "";
        return res
    }).catch(handleAxiosError("Failed to load entries"));
};

export const useFetchEntryQuery = (entryId: string) => {
    return useQuery({
        queryKey: ["epr", "entries", entryId],
        queryFn: () => axios.post(
            `${baseUrl}/epr/read`,
            {"id": entryId},
            getAxiosRequestOptions(),
        ).then((rsp) => {
            return rsp.data;
        }).catch(handleAxiosError("Failed to load entry")),
        staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours, cache expiry should ensure new entries are fetched.
    });
}

export const invalidateEprCache = async (queryClient: QueryClient): Promise<void> => {
    return queryClient.invalidateQueries({queryKey: ["epr"]});
};
